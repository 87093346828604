import React from "react";
import { useForm } from "react-hook-form";
import { TextField, Button } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTitle from "./Typography/FormTitle";

const schema = yup.object().shape({
  title: yup.string(),
  isbn13: yup.string(),
  author: yup.string(),
  publisher: yup.string(),
});

const SearchBooks = ({ getSuggestions, searchTerms }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    getSuggestions(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Bücher suchen</FormTitle>
      <TextField
        fullWidth
        name="title"
        label="Titel"
        defaultValue={searchTerms.title}
        inputRef={register}
        error={errors.title}
        helperText={errors.title?.message}
      />
      <TextField
        fullWidth
        name="author"
        label="Autor"
        defaultValue={searchTerms.author}
        inputRef={register}
        error={errors.author}
        helperText={errors.author?.message}
      />
      <TextField
        fullWidth
        name="isbn13"
        label="ISBN13"
        defaultValue={searchTerms.isbn13}
        inputRef={register}
        error={errors.isbn13}
        helperText={errors.isbn13?.message}
      />
      <TextField
        fullWidth
        name="publisher"
        label="Publisher"
        defaultValue={searchTerms.publisher}
        inputRef={register}
        error={errors.publisher}
        helperText={errors.publisher?.message}
      />
      <br /> <br />
      <Button variant="contained" type="submit" color="primary">
        Suchen
      </Button>
    </form>
  );
};

export default SearchBooks;
