import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Languages from "./Fields/Languages";
import ReadingStatus from "./Fields/ReadingStatus";
import LinkOrUploadThumbnails from "./Fields/LinkOrUploadThumbnails";
import FormTitle from "./Typography/FormTitle";
import Link from "@material-ui/core/Link";
import ISBN13 from "./Fields/ISBN13";
import GoogleVolumeID from "./Fields/GoogleVolumeID";
import Title from "./Fields/Title";
import Published from "./Fields/Published";
import Pages from "./Fields/Pages";
import Publisher from "./Fields/Publisher";
import FormSubtitle from "./Typography/FormSubtitle";
import Description from "./Fields/Description";
import {FormApiPost} from "./FormApi";
import Authors from "./Fields/Authors";
import Genres from "./Fields/Genres";
import GoogleBook from "../Model/GoogleBook";

const TwoInputs = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const schema = yup.object().shape({
  google_volume_id: yup.string(),
  isbn13: yup.number().positive().integer().required(),
  title: yup.string().required(),
  description: yup.string(),
  published: yup.date().required(),
  pages: yup.number().positive().integer().required(),
  language: yup.string().required(),
  publisher: yup.string().required(),
  image_url_small: yup.string().url().required(),
  image_url: yup.string().url().required(),
  status: yup.string().required(),
  started_reading: yup.date(),
  finished_reading: yup.date(),
  active: yup.boolean(),
});

const NewBook = ({ bookToBeAdded, setBookToBeAdded }) => {
    bookToBeAdded = new GoogleBook(bookToBeAdded);
  console.log(bookToBeAdded);
  const { register, handleSubmit, watch, errors, control } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    data.authors = data.authors.map((author) => ({
      name: author.value,
    }));
    data.genres = data.genres.map((genre) => ({
        name: genre.value,
    }));
    console.log(data);
    const serverResponse = await FormApiPost("api/books/create", data);
    setShowMessage(serverResponse);
  };

  const [showMessage, setShowMessage] = useState(false);

  const status = watch("status"); // damit man conditional fields hinzufügen kann
  const uploadOrLink = watch("uploadOrLink");
  const image_url = watch("image_url");
  const image_url_small = watch("image_url_small");
  const authors = watch("authors");
  const genres = watch("genres");

  const handleClose = (event, reason) => {
    // Handle close of Success Message
    if (reason === "clickaway") {
      return;
    }
    setShowMessage(false);
  };

  return (
    // name of input = Laravel Book Model
    // defaultValue = Google Books API Model
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Ein neues Buch hinzufügen</FormTitle>
      <Title
        register={register}
        errors={errors}
        defaultValue={`${bookToBeAdded.title}${bookToBeAdded.subtitle ? (": " + bookToBeAdded.subtitle) : ""}`}
      />
      <Authors
        defaultValue={bookToBeAdded.authors}
        control={control}
        errors={errors}
        authorsInReactHookForm={authors}
        newBook={true}
      />
      <Description
        errors={errors}
        defaultValue={bookToBeAdded.description}
        register={register}
      />
      <FormSubtitle>Metadaten</FormSubtitle>
      <Link
        rel="noopener"
        href={
          "https://www.googleapis.com/books/v1/volumes/" +
          bookToBeAdded.volumeId
        }
        target="_blank"
      >
        Zur Google API
      </Link>
      <TwoInputs>
        <GoogleVolumeID
          errors={errors}
          register={register}
          defaultValue={bookToBeAdded.volumeId}
        />

        <ISBN13
          errors={errors}
          register={register}
          defaultValue={
            bookToBeAdded.industryIdentifiers &&
            ((bookToBeAdded.industryIdentifiers[0] &&
              bookToBeAdded.industryIdentifiers[0].type === "ISBN_13" &&
              bookToBeAdded.industryIdentifiers[0].identifier) ||
              (bookToBeAdded.industryIdentifiers[1] &&
                bookToBeAdded.industryIdentifiers[1].type === "ISBN_13" &&
                bookToBeAdded.industryIdentifiers[1].identifier))
          }
        />
      </TwoInputs>
      <TwoInputs>
        <Published
          register={register}
          defaultValue={bookToBeAdded.publishedDate}
          errors={errors}
        />
        <Pages
          errors={errors}
          defaultValue={bookToBeAdded.pageCount}
          register={register}
        />
      </TwoInputs>
      <Languages
        control={control}
        defaultLanguage={bookToBeAdded.language}
        errors={errors}
      />
        <Genres
            defaultValue={bookToBeAdded.categories}
            control={control}
            errors={errors}
            genresInReactHookForm={genres}
            newBook={true}
        />
      <Publisher
        errors={errors}
        register={register}
        defaultValue={bookToBeAdded.publisher}
      />
      <LinkOrUploadThumbnails
        register={register}
        errors={errors}
        image_url_small={
          image_url_small ||
          (bookToBeAdded.imageLinks && bookToBeAdded.imageLinks.smallThumbnail)
        }
        image_url={
          image_url ||
          (bookToBeAdded.imageLinks && bookToBeAdded.imageLinks.thumbnail)
        }
        uploadOrLink={uploadOrLink}
        control={control}
      />
      <ReadingStatus
        control={control}
        errors={errors}
        register={register}
        status={status}
      />
      <br />
      Buch auf Website anzeigen:{" "}
      <input
        type="checkbox"
        defaultChecked={true}
        name="active"
        ref={register}
      />{" "}
      <br />
      <br />
      {errors.active?.message}
      <br /> <br />
      <Button variant="contained" type="submit" color="primary">
        Buch hinzufügen
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setBookToBeAdded(false)}
      >
        Abbrechen
      </Button>
      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={showMessage === true ? "success" : "error"}
        >
          {showMessage === true
            ? "Das Buch wurde erfolgreich der Datenbank hinzugefügt!"
            : showMessage}
        </MuiAlert>
      </Snackbar>
    </form>
  );
};

export default NewBook;
