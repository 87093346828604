import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";

const Title = ({ register, defaultValue, errors }) => {
  return (
    <TextField
      required
      fullWidth
      label="Titel"
      name="title"
      defaultValue={defaultValue}
      inputRef={register}
      error={errors.title}
      helperText={errors.title?.message}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MenuBookIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Title;
