import { TextField } from "@material-ui/core";
import React from "react";

const Pages = ({ register, defaultValue, errors }) => {
  return (
    <TextField
      required
      fullWidth
      label="Seitenanzahl"
      name="pages"
      defaultValue={defaultValue}
      inputRef={register}
      error={errors.pages}
      helperText={errors.pages?.message}
    />
  );
};

export default Pages;
