import { TextField } from "@material-ui/core";
import React from "react";

const Description = ({ register, defaultValue, errors }) => {
  return (
    <TextField
      fullWidth
      multiline
      label="Beschreibung (optional)"
      name="description"
      defaultValue={defaultValue}
      inputRef={register}
      error={errors.description}
      helperText={errors.description?.message}
    />
  );
};

export default Description;
