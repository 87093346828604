import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Languages from "./Fields/Languages";
import ReadingStatus from "./Fields/ReadingStatus";
import LinkOrUploadThumbnails from "./Fields/LinkOrUploadThumbnails";
import FormTitle from "./Typography/FormTitle";
import Link from "@material-ui/core/Link";
import ISBN13 from "./Fields/ISBN13";
import GoogleVolumeID from "./Fields/GoogleVolumeID";
import Title from "./Fields/Title";
import Published from "./Fields/Published";
import Pages from "./Fields/Pages";
import Publisher from "./Fields/Publisher";
import FormSubtitle from "./Typography/FormSubtitle";
import Description from "./Fields/Description";
import {FormApiPost, FormApiPut} from "./FormApi";
import Authors from "./Fields/Authors";
import Genres from "./Fields/Genres";
import Differences from "../Model/Differenes";
import Book from "../Model/Book";

const TwoInputs = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const schema = yup.object().shape({
    google_volume_id: yup.string(),
    isbn13: yup.number().positive().integer().required(),
    title: yup.string().required(),
    description: yup.string(),
    published: yup.date().required(),
    pages: yup.number().positive().integer().required(),
    language: yup.string().required(),
    publisher: yup.string().required(),
    image_url_small: yup.string().url().required(),
    image_url: yup.string().url().required(),
    status: yup.string().required(),
    started_reading: yup.date(),
    finished_reading: yup.date(),
    active: yup.boolean(),
});

const BookForm = ({ book, pageTitle, successMessage, ButtonText }) => {
    const bookAuthors = [];
    const bookGenres = [];
    book.authors.forEach(author => {bookAuthors.push(author.name)});
    book.genres.forEach(genre => {bookGenres.push(genre.name)});
    const { register, handleSubmit, watch, errors, control } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        data.authors = data.authors.map((author) => ({
            name: author.value,
        }));
        data.genres = data.genres.map((genre) => ({
            name: genre.value,
        }));

        // create differences as an object to be sent to backend
        const oldBook = new Book(book);
        oldBook.authors = bookAuthors;
        oldBook.genres = bookGenres;
        oldBook.published = new Date(oldBook.published);
        const newBook = new Book(data);
        console.log(oldBook);
        console.log(newBook);
        const bookToUpdate = Differences(oldBook, newBook);
        // delete properties from newBook
        deleteProps(bookToUpdate, ['id', 'uploadOrLink', 'updated_at', 'slug', 'deleted_at', 'created_at']);
        if (oldBook.active === 1 && newBook.active) {delete bookToUpdate.active;}

        // Todo: change when done. Derzeit noch keine Änderungen schicken von folgenden Feldern:
        deleteProps(bookToUpdate, ['authors', 'genres', 'published', 'started_reading', 'finished_reading']);

        console.log(bookToUpdate)
        const serverResponse = await FormApiPut("api/books/" + book.id, bookToUpdate);
        setShowMessage(serverResponse);
    };

    const deleteProps = (obj, prop) => {
        for (const p of prop) {
            (p in obj) && (delete obj[p]);
        }
    }

    const [showMessage, setShowMessage] = useState(false);

    const status = watch("status"); // damit man conditional fields hinzufügen kann
    const uploadOrLink = watch("uploadOrLink");
    const image_url = watch("image_url");
    const image_url_small = watch("image_url_small");
    const authors = watch("authors");
    const genres = watch("genres");


    const handleClose = (event, reason) => {
        // Handle close of Success Message
        if (reason === "clickaway") {
            return;
        }
        setShowMessage(false);
    };

    return (
        // name of input = Laravel Book Model
        // defaultValue = Google Books API Model
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormTitle>{pageTitle || "Buch editieren"}</FormTitle>
            <Title
                register={register}
                errors={errors}
                defaultValue={book.title}
            />
            <Authors
                defaultValue={bookAuthors}
                control={control}
                errors={errors}
                authorsInReactHookForm={authors}
                newBook={false}
            />
            <Description
                errors={errors}
                defaultValue={book.description}
                register={register}
            />
            <FormSubtitle>Metadaten</FormSubtitle>
            <Link
                rel="noopener"
                href={
                    "https://www.googleapis.com/books/v1/volumes/" +
                    book.google_volume_id
                }
                target="_blank"
            >
                Zur Google API
            </Link>
            <TwoInputs>
                <GoogleVolumeID
                    errors={errors}
                    register={register}
                    defaultValue={book.google_volume_id}
                />

                <ISBN13
                    errors={errors}
                    register={register}
                    defaultValue={book.isbn13}
                />
            </TwoInputs>
            <TwoInputs>
                <Published
                    register={register}
                    defaultValue={book.published}
                    errors={errors}
                />
                <Pages
                    errors={errors}
                    defaultValue={book.pages}
                    register={register}
                />
            </TwoInputs>
            <Languages
                control={control}
                defaultLanguage={book.language}
                errors={errors}
            />
            <Genres
                defaultValue={bookGenres}
                control={control}
                errors={errors}
                genresInReactHookForm={genres}
                newBook={true}
            />
            <Publisher
                errors={errors}
                register={register}
                defaultValue={book.publisher}
            />
            <LinkOrUploadThumbnails
                register={register}
                errors={errors}
                image_url_small={image_url_small || book.image_url_small}
                image_url={image_url || book.image_url}
                uploadOrLink={uploadOrLink}
                control={control}
            />
            <ReadingStatus
                control={control}
                errors={errors}
                register={register}
                status={status}
                defaultValue={book.status}
                defaultStart={book.started_reading}
                defaultEnd={book.finished_reading}
            />
            <br />
            Buch auf Website anzeigen:{" "}
            <input
                type="checkbox"
                defaultChecked={book.active}
                name="active"
                ref={register}
            />{" "}
            <br />
            <br />
            {errors.active?.message}
            <br /> <br />
            <Button variant="contained" type="submit" color="primary">
                {ButtonText || "Buch aktualisieren"}
            </Button>
            <Snackbar
                open={showMessage}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={showMessage === true ? "success" : "error"}
                >
                    {showMessage === true
                        ? (successMessage || "Buch aktualisiert")
                        : showMessage}
                </MuiAlert>
            </Snackbar>
        </form>
    );
};

export default BookForm;
