import Typography from "@material-ui/core/Typography";
import React from "react";

const FormTitle = ({ children }) => {
  return (
    <Typography variant="h3" color={"primary"} gutterBottom={true}>
      {children}
    </Typography>
  );
};

export default FormTitle;
