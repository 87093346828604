import { TextField } from "@material-ui/core";
import React from "react";

const Publisher = ({ register, defaultValue, errors }) => {
  return (
    <TextField
      required
      fullWidth
      label="Publisher"
      name="publisher"
      defaultValue={defaultValue}
      inputRef={register}
      error={errors.publisher}
      helperText={errors.publisher?.message}
    />
  );
};

export default Publisher;
