import React, { useEffect, useState } from "react";
import axios from "axios";
import {url} from "../../config";

import AsyncCreatableSelect from "react-select/creatable";
import { Controller } from "react-hook-form";
import {FormApiPost} from "../FormApi";

const Authors = ({ defaultValue, control, newBook, editing }) => {
  useEffect(() => {

    if (newBook) {
      // first create all authors from defaultValues
      defaultValue && defaultValue.forEach(async (author) => await createNewAuthor(author));
    }

    // get authors from database on first load
    getAuthors();
  }, []);
  const modifiedDefaultValue = !defaultValue ? {} : defaultValue.map((author) => ({
    value: author,
    label: author,
  }));

  const [selectOptions, setSelectOption] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getAuthors = async () => {
    // get Authors from Backend for options list
    try {
      const response = await axios.get(url + "authors/all");
      setSelectOption(
        response.data.map((option) => ({
          value: option.name,
          label: option.name,
        }))
      );
      setIsLoading(false);
    } catch (e) {
      console.log(e.message);
    }
  };

  const createNewAuthor = async (author) => {
    // create new Author in Backed
    try {
      author = { name: author };
      console.log("Creating New Author: ", author);
      await FormApiPost("api/authors/create", author)
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleCreate = async (author) => {
    setIsLoading(true);
    await createNewAuthor(author); // create author in backend
    // setAuthors([...authors, author]);
    setIsLoading(false);

    await getAuthors(); // refresh options list
  };

  return (
    <div>
      Autoren
      <Controller
        control={control}
        name="authors"
        defaultValue={modifiedDefaultValue}
        onChange={([selected]) => {
          // React Select return object instead of value for selection
          return { value: selected };
        }}
        as={
          <AsyncCreatableSelect
            cacheOptions
            defaultOptions
            isMulti
            //onChange={handleChange}
            onCreateOption={handleCreate}
            options={selectOptions}
            isLoading={isLoading}
            //value={authors}
          />
        }
      />
    </div>
  );
};

export default Authors;

/*value={authors.map((author) => ({
              value: author,
              label: author,
            }))}*/
