import React from "react";
import { TextField } from "@material-ui/core";
import UploadImageDropzone from "./UploadImageDropzone";
import styled from "styled-components";
import RadioGroup from "../CustomInput/RadioGroup";
import FormSubtitle from "../Typography/FormSubtitle";

const TwoInputs = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const BookThumbnail = styled.img`
  height: 100px;
`;

const LinkOrUploadThumbnails = ({
  control,
  errors,
  register,
  image_url_small,
  image_url,
  uploadOrLink,
}) => {
  const radioValues = [
    { value: "url", text: "URL" },
    { value: "upload", text: "Bild hochladen" },
  ];

  return (
    <React.Fragment>
      <FormSubtitle>Thumbnails</FormSubtitle>

      <RadioGroup
        name="uploadOrLink"
        horizontal
        values={radioValues}
        control={control}
        defaultValue="url"
      />

      {uploadOrLink === "upload" ? (
        <UploadImageDropzone />
      ) : (
        <TwoInputs>
          <React.Fragment>
            <BookThumbnail src={image_url_small} />
            <TextField
              fullWidth
              multiline
              required
              name="image_url_small"
              label="Thumbnail"
              defaultValue={image_url_small}
              inputRef={register}
              error={errors.image_url_small}
              helperText={errors.image_url_small?.message}
            />
          </React.Fragment>
          <React.Fragment>
            <BookThumbnail src={image_url} />
            <TextField
              fullWidth
              multiline
              required
              name="image_url"
              label="Grosses Thumbnail"
              defaultValue={image_url}
              inputRef={register}
              error={errors.image_url}
              helperText={errors.image_url?.message}
            />
          </React.Fragment>
        </TwoInputs>
      )}
    </React.Fragment>
  );
};

export default LinkOrUploadThumbnails;
