import React from "react";
import { DropzoneArea } from "material-ui-dropzone";

const UploadImageDropzone = () => {
  return (
    <div>
      <DropzoneArea
        acceptedFiles={["image/*"]}
        cancelButtonText={"Abbrechen"}
        submitButtonText={"Hochladen"}
        dropzoneText={"Ziehe das Bild hinein oder klick hier"}
        maxFileSize={5000000}
        filesLimit={1}
        onChange={(files) => {
          console.log("Files:", files);
        }}
        previewText={"Vorschau"}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: "row" } }}
        showAlerts={false}
      />
    </div>
  );
};

export default UploadImageDropzone;
