import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-top: 2rem;
  margin: 0;
`;

const FormSubtitle = ({ children }) => {
  return (
    <Wrapper>
      <Typography variant="h6" color={"primary"} gutterBottom={false}>
        {children}
      </Typography>
    </Wrapper>
  );
};

export default FormSubtitle;
