import { InputAdornment, TextField } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React from "react";

const Published = ({ register, defaultValue, errors }) => {
  return (
    <TextField
      fullWidth
      required
      label="Veröffentlichungsdatum"
      name="published"
      type="date"
      defaultValue={defaultValue}
      inputRef={register}
      error={errors.published}
      helperText={errors.published?.message}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalendarTodayIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Published;
