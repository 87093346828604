import React from "react";
import { TextField } from "@material-ui/core";

const ISBN13 = ({ register, defaultValue, errors }) => {
  return (
    <TextField
      required
      fullWidth
      label="ISBN 13"
      name="isbn13"
      defaultValue={defaultValue}
      inputRef={register}
      error={errors.isbn13}
      helperText={errors.isbn13?.message}
    />
  );
};

export default ISBN13;
