/**
 * Book model
 */
class Book {
  constructor(data = {}) {
    // Book according to internal database schema

    this.id = null;
    this.title = null;
    this.slug = null;
    this.isbn13 = null;
    this.description = null;
    this.published = null;
    this.publisher  = null;
    this.pages = null;
    this.image_url = null;
    this.image_url_small = null;
    this.started_reading = null;
    this.finished_reading = null;
    this.active = null;
    this.status = null;
    this.language = null;
    this.authors = [];
    this.genres = [];

    Object.assign(this, data);
  }
}
export default Book;