import axios from "axios";
import {url} from "../config"

const axiosInstance = axios.create({
  baseURL: url
});
axios.defaults.baseURL = url;

export const LoginPost = async (data) => {
  try {
    console.log(data);

    const response = await axios.post("login", data);

    console.log(response.data.token);

    localStorage.setItem('token', response.data.token);

    window.location.reload(false);

  } catch (e) {
    errorHandling(e);
  }
}

export const LogoutPost = async (data) => {
  try {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}` ;

    const response = await axiosInstance.post("api/logout", data);
    console.log(response.data);

    localStorage.removeItem('token');

    window.location.reload(false);

    return true; // everything went well
  } catch (e) {
    errorHandling(e);
    localStorage.removeItem('token');
    window.location.reload(false);
    return false;
  }
};

export const FormApiGet = async (uri) => {
  try {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}` ;

    const response = await axiosInstance.get(uri);

    return response.data; // everything went well
  } catch (e) {
    errorHandling(e);
  }
}

export const FormApiPost = async (uri,data) => {
  try {
    //console.log(data);

    // Alter defaults after instance has been created
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}` ;

    const response = await axiosInstance.post( uri, data);
    //console.log(response.data);

    return true; // everything went well
  } catch (e) {
    errorHandling(e);
  }
};

export const FormApiPut = async (uri,data) => {
  try {
    console.log(data);

    const response = await axiosInstance.put(uri, data);
    console.log(response.data);

    return true; // everything went well
  } catch (e) {
    errorHandling(e);
  }
};


const errorHandling = (e) => {
  if (e.response) {
    console.log("Request made and server responded");
    return (
        e.message +
        ". " +
        JSON.stringify(e.response.data) +
        ". " +
        JSON.stringify(e.response.status) +
        ". " +
        JSON.stringify(e.response.headers)
    );
  } else if (e.request) {
    console.log("The request was made but no response was received");

    return JSON.stringify(e.request);
  } else {
    console.log(
        "Something happened in setting up the request that triggered an Error"
    );
    return e.message;
  }
}