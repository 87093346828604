import React from "react";

import FormTitle from "../Forms/Typography/FormTitle";
import { Typography } from "@material-ui/core";
import Login from "../Login/Login";

const Home = () => {
  return (
    <React.Fragment>
        <FormTitle>Dashboard</FormTitle>
        <Typography variant="p">
          Hier folgt eine Übersicht wie viele Bücher, Autoren, Genres etc. in der
          Datenbank sind. Wie viele von Gäster empfohlen wurden, wie viele von
          Goodreads noch in der Pipeline sind. Ausserdem ein prompt um die derzeit
          gelesenen Bücher zu aktualisieren (Datum).
        </Typography>
    </React.Fragment>
  );
};

export default Home;
