/**
 * Google Book model
 */
class GoogleBook {
  constructor(data = {}) {
    // Book according to Google API Data Model.
    // Might need to be adjusted for LARAVEL internal Model!
    this.volumeId = data.id || null; // separat, da nicht in volumeInfo drinn
    this.title = null;
    this.subtitle = null;
    this.authors = null;
    this.publisher = null;
    this.publishedDate = null;
    this.description = null;
    this.pageCount = null;
    this.categories = null;
    this.industryIdentifiers = null; // isbn13 und isbn10
    this.imageLinks = null;
    this.language = null;

    Object.assign(this, data);
  }
}
export default GoogleBook;
