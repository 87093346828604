import React from "react";
import { Controller } from "react-hook-form";

import RadioMUI from "@material-ui/core/Radio";
import RadioGroupMUI from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const RadioGroup = ({ control, name, values, defaultValue, ...props }) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      as={
        <RadioGroupMUI row defaultValue={defaultValue} {...props}>
          {values.map((value) => (
            <FormControlLabel
              control={<RadioMUI value={value.value} />}
              label={value.text}
              labelPlacement="start"
            />
          ))}
        </RadioGroupMUI>
      }
      control={control}
    />
  );
};

export default RadioGroup;
