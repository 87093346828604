import React from "react";

import styled from "styled-components";
import GoogleBook from "../Model/GoogleBook";
import { Button, Paper, Grid, Typography, ButtonBase } from "@material-ui/core";

const BookContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0 1rem 0;
  & > div {
    width: 100%;
    padding: 1rem;
  }
`;

const SearchedBooks = ({ data, setBookToBeAdded }) => {
  return (
    <>
      <br />
      <Typography variant="h4" component="h2" gutterBottom>
        Ergebnisse
      </Typography>
      {data.map((item) => {
        const book = new GoogleBook(item && item.volumeInfo);
        return (
          <BookContainer>
            <Paper>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ButtonBase>
                    <img
                      src={
                        book.imageLinks &&
                        (book.imageLinks.smallThumbnail ||
                          book.imageLinks.thumbnail)
                      }
                    />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography gutterBottom variant="subtitle1">
                        {book.title} {book.subtitle}{" "}
                        {book.language &&
                          "(" + book.language.toUpperCase() + ")"}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {book.authors &&
                          book.authors.map((author) => {
                            return author + " ";
                          })}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {book.publisher} | {book.publishedDate} <br />
                        Genres:{" "}
                        {book.categories &&
                          book.categories.map((category) => {
                            return category + " ";
                          })}{" "}
                        <br />
                        ISBN 13:{" "}
                        {book.industryIdentifiers &&
                          book.industryIdentifiers.map((isbn) => {
                            return (
                              isbn.type &&
                              isbn.type === "ISBN_13" &&
                              isbn.identifier
                            );
                          })}{" "}
                        <br />
                        {book.pageCount && book.pageCount + " Seiten "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => setBookToBeAdded(book)}
                        variant="contained"
                        type="submit"
                      >
                        Buch hinzufügen
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </BookContainer>
        );
      })}
    </>
  );
};

export default SearchedBooks;
