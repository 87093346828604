import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';

const Email = ({ register, defaultValue, errors }) => {
    return (
        <TextField
            required
            fullWidth
            label="Email"
            name="email"
            defaultValue={defaultValue}
            inputRef={register}
            error={errors.email}
            helperText={errors.email?.message}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <EmailIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default Email;
