import React from "react";
import { TextField } from "@material-ui/core";

const GoogleVolumeID = ({ register, defaultValue, errors }) => {
  return (
    <TextField
      fullWidth
      label="Volume-ID (optional)"
      name="google_volume_id"
      defaultValue={defaultValue}
      inputRef={register}
      error={errors.google_volume_id}
      helperText={errors.google_volume_id?.message}
    />
  );
};

export default GoogleVolumeID;
