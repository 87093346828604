import React, { useEffect, useState } from "react";
import axios from "axios";
import {url} from "../../config";

import AsyncCreatableSelect from "react-select/creatable";
import { Controller } from "react-hook-form";
import {FormApiPost} from "../FormApi";

const Genres = ({ defaultValue, control, newBook, editing }) => {
    useEffect(() => {

        if (newBook) {
            // first create all genres from defaultValues
            defaultValue && defaultValue.forEach(async (genre) => await createNewGenre(genre));
        }

        // get genres from database on first load
        getGenres();
    }, []);
    const modifiedDefaultValue = !defaultValue ? {} : defaultValue.map((genre) => ({
        value: genre,
        label: genre,
    }));

    const [selectOptions, setSelectOption] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const getGenres = async () => {
        // get Genres from Backend for options list
        try {
            const response = await axios.get(url + "genres/all");
            setSelectOption(
                response.data.map((option) => ({
                    value: option.name,
                    label: option.name,
                }))
            );
            setIsLoading(false);
        } catch (e) {
            console.log(e.message);
        }
    };

    const createNewGenre = async (genre) => {
        // create new Genre in Backed
        try {
            genre = { name: genre };
            //console.log("Creating New Genre: ", genre);
            await FormApiPost("api/genres/create", genre)
        } catch (e) {
            console.log(e.message);
        }
    };

    const handleCreate = async (genre) => {
        setIsLoading(true);
        await createNewGenre(genre); // create genre in backend
        setIsLoading(false);

        await getGenres(); // refresh options list
    };

    return (
        <div>
            Genres
            <Controller
                control={control}
                name="genres"
                defaultValue={modifiedDefaultValue}
                onChange={([selected]) => {
                    // React Select return object instead of value for selection
                    return { value: selected };
                }}
                as={
                    <AsyncCreatableSelect
                        cacheOptions
                        defaultOptions
                        isMulti
                        onCreateOption={handleCreate}
                        options={selectOptions}
                        isLoading={isLoading}
                    />
                }
            />
        </div>
    );
};

export default Genres;