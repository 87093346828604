import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTitle from "../Forms/Typography/FormTitle";
import Email from "../Forms/Fields/Email";
import Password from "../Forms/Fields/Password";
import {LoginPost} from "../Forms/FormApi";


const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

const Login = () => {
    const { register, handleSubmit, watch, errors, control } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        const serverResponse = await LoginPost(data);
        setShowMessage(serverResponse);
    };

    const [showMessage, setShowMessage] = useState(false);

    const handleClose = (event, reason) => {
        // Handle close of Success Message
        if (reason === "clickaway") {
            return;
        }
        setShowMessage(false);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormTitle>Login</FormTitle>
            <Email
                register={register}
                errors={errors}
                defaultValue={""}
            />
            <Password
                register={register}
                errors={errors}
                defaultValue={""}
            />

            <br /> <br />
            <Button variant="contained" type="submit" color="primary">
                Login
            </Button>
            <Snackbar
                open={showMessage}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={showMessage === true ? "success" : "error"}
                >
                    {showMessage === true
                        ? "Login erfolgreich!"
                        : showMessage}
                </MuiAlert>
            </Snackbar>
        </form>
    );
};

export default Login;
