import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Controller} from "react-hook-form";
import React from "react";

const Languages = ({control, defaultLanguage, errors}) => {

    const languages_list = [
        { value: "en", text: "Englisch" },
        { value: "de", text: "Deutsch" },
        { value: "fr", text: "Französisch" },
    ];

    // set default language to german if none given
    defaultLanguage = defaultLanguage ? defaultLanguage : "de";

    return (
        <React.Fragment>
            <FormControl fullWidth>
                <InputLabel>Sprache</InputLabel>
                <Controller
                    control={control}
                    name="language"
                    defaultValue={defaultLanguage.toLowerCase()}
                    as={
                        <Select id="language-select">
                            {languages_list.map(lang => (
                                <MenuItem key={lang.value} value={lang.value}>
                                    {lang.text}
                                </MenuItem>
                            ))}
                        </Select>
                    }
                />
            </FormControl>
            {errors.language?.message}
        </React.Fragment>
    );
}

export default Languages;
