import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const Password = ({ register, defaultValue, errors }) => {
    return (
        <TextField
            required
            fullWidth
            label="Password"
            name="password"
            defaultValue={defaultValue}
            inputRef={register}
            error={errors.password}
            helperText={errors.password?.message}
            type="password"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <VpnKeyIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default Password;
