import React, { useEffect, useState } from "react";

import FormTitle from "../Forms/Typography/FormTitle";
import { Typography } from "@material-ui/core";
import Book from "../Model/Book";
import {Link} from "react-router-dom";
import {FormApiGet} from "../Forms/FormApi";

const BooksOverview = () => {
  useEffect(() => {
    getBooks();
  }, []);

  const [books, setBooks] = useState([]);

  const getBooks = async () => {
    // get books from Backend
    try {
      setBooks(await FormApiGet('books/pages/all'));
    } catch (e) {
      console.log(e.message);
    }
  };
  return (
    <React.Fragment>
      <FormTitle>Books Overview</FormTitle>
      {books.map((book) => {
        return <BookItem book={book} />;
      })}
    </React.Fragment>
  );
};

export default BooksOverview;

const BookItem = ({ book }) => {
  book = new Book(book);
  return (
    <React.Fragment>
        <Link to={"/books/" + book.id}>
      <Typography variant="h6">
        {book.id}: {book.title}
      </Typography>
      <img src={book.image_url_small} height="75px" />
      {book.authors.map((author) => {
        return author.name;
      })}
      {book.genres.map((genre) => {
        return genre.name;
      })}
      <br />
      Status: {book.status}
      <br /> <br />
        </Link>
    </React.Fragment>
  );
};
