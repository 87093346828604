import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import BookForm from "../Forms/BookForm";
import axios from "axios";
import {url} from "../config"
import {CenterContent} from "../Styles/Styles";
import {CircularProgress} from "@material-ui/core";

function EditBook() {
    let { bookId } = useParams();

    useEffect(() => {
        getBook();
    }, []);

    const [book, setBook] = useState(null);
    const [finished, setFinished] = useState(false);

    const getBook = async () => {
        // get books from Backend
        try {
            const response = await axios.get(url + "books/" + bookId);
            setBook(response.data);
            setFinished(true);
        } catch (e) {
            console.log(e.message);
        }
    };

    return (
        <React.Fragment>
            {finished ?
                <BookForm book={book} pageTitle={"Buch " + bookId + " editieren"}/>
                :
                (<CenterContent>
                    <CircularProgress />
                </CenterContent>)
            }
        </React.Fragment>
    );
}

export default EditBook;