import React from "react";
import ReactDOM from "react-dom";
import AddBook from "./Components/AddBook";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import HomeIcon from "@material-ui/icons/Home";
import AddBoxIcon from "@material-ui/icons/AddBox";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import CategoryIcon from "@material-ui/icons/Category";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Home from "./Components/Home";
import styled from "styled-components";
import BooksOverview from "./Components/BooksOverview";
import EditBook from "./Components/EditBook";
import {LogoutPost} from "./Forms/FormApi";
import {Button} from "@material-ui/core";
import Login from "./Login/Login";

const Main = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 800px;
`;

ReactDOM.render(
  <React.StrictMode>
    {localStorage.getItem('token') === null
        ?
        <Main>
          <Container>
            <Login />
          </Container>
        </Main>
        :
        (
    <Router>
      <BottomNavigation>
        <Link to="/">
          <BottomNavigationAction
            showLabel={true}
            label="Home"
            icon={<HomeIcon />}
          />
        </Link>
        <Link to="/addBook">
          <BottomNavigationAction
            showLabel={true}
            label="Neues Buch"
            icon={<AddBoxIcon />}
          />
        </Link>
        <Link to="/books">
          <BottomNavigationAction
            showLabel={true}
            label="Bücher"
            icon={<MenuBookIcon />}
          />
        </Link>
        <BottomNavigationAction
          showLabel={true}
          label="Autoren"
          icon={<PeopleAltIcon />}
        />
        <BottomNavigationAction
          showLabel={true}
          label="Genres"
          icon={<CategoryIcon />}
        />
        <Button onClick={async () => await LogoutPost()}>
          <ExitToAppIcon /> Logout
        </Button>
      </BottomNavigation>
      <Main>
        <Container>
          <Switch>
            <Route path="/addBook">
              <AddBook />
            </Route>
            <Route exact path="/books/:bookId">
              <EditBook />
            </Route>
            <Route exact path="/books">
              <BooksOverview />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Container>
      </Main>
    </Router>
        )
    }
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
