import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React from "react";
import styled from "styled-components";
import FormSubtitle from "../Typography/FormSubtitle";

const TwoInputs = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const ReadingStatus = ({ control, defaultValue, errors, register, status, defaultStart, defaultEnd }) => {
  const readingStatus = [
    { value: "reading", text: "Am lesen" },
    { value: "stopped", text: "Aufgehört zu lesen" },
    { value: "read", text: "Gelesen" },
    { value: "to-read", text: "Habe vor zu lesen" },
  ];

  defaultValue = defaultValue ? defaultValue : "to-read";

  return (
    <React.Fragment>
      <FormSubtitle>Status</FormSubtitle>

      <FormControl fullWidth>
        <InputLabel>Lese-Status</InputLabel>
        <Controller
          control={control}
          name="status"
          defaultValue={defaultValue}
          as={
            <Select defaultValue={defaultValue} id="status-select">
              {readingStatus.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.text}
                </MenuItem>
              ))}
            </Select>
          }
        />
      </FormControl>
      {errors.status?.message}

      <TwoInputs>
        {((!status && defaultValue && defaultValue !== "to-read ") || (status && status !== "to-read")) && (
          // Zeigen, ausser noch nicht angefangen zu lesen
          <TextField
            fullWidth
            type="date"
            name="started_reading"
            label="Gestartet mit Lesen am"
            inputRef={register}
            defaultValue={
                defaultStart ||
                (
                  new Date().getFullYear() +
                  "-" +
                  new Date().getMonth() +
                  "-" +
                  new Date().getDate()
                )
            }
            error={errors.started_reading}
            helperText={errors.started_reading?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
        )}

        {((!status && defaultValue && defaultValue === "read") || (status && status === "read")) && (
          // nur zeigen, wenn Buch fertig
          <TextField
            fullWidth
            type="date"
            name="finished_reading"
            label="Fertig gelesen am"
            inputRef={register}
            defaultValue={
                defaultEnd ||
                (
                    new Date().getFullYear() +
                    "-" +
                    new Date().getMonth() +
                    "-" +
                    new Date().getDate()
                )
            }
            error={errors.finished_reading}
            helperText={errors.finished_reading?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      </TwoInputs>
    </React.Fragment>
  );
};

export default ReadingStatus;
