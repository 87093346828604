import React, { useState } from "react";
import SearchBooks from "../Forms/SearchBooks";
import axios from "axios";
import NewBook from "../Forms/NewBook";
import { CircularProgress } from "@material-ui/core";
import {CenterContent} from "../Styles/Styles";
import SearchedBooks from "./SearchedBooks";
import {FormApiGet} from "../Forms/FormApi";

function AddBook() {
  const [results, setResults] = useState("");
  const [error, setError] = useState(false);
  const [searching, setSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [bookToBeAdded, setBookToBeAdded] = useState(false);
  const [searchTerms, setSearchTerms] = useState({
    author: "",
    isbn13: "",
    publisher: "",
    title: "",
  }); // saves Search so it can display the results again after adding a book
  const getSuggestions = async (data) => {
    //console.log(data);
    try {
      setSearching(true);
      setSearchTerms(data);
      const { isbn13, title, publisher, author } = data;

      let url =
        "api/googleApi/books?isbn=" +
        isbn13 +
        "&title=" +
        title +
        "&author=" +
        author +
        "&publisher=" +
        publisher;
      const response = await FormApiGet(url);
      setResults(response.items);
      setBookToBeAdded(false);
      setError(false);
      setSearching(false);
      setHasSearched(true);
    } catch (error) {
      setError(true);
      console.error(error);
    }
  };
  return (
    <React.Fragment>
      {!bookToBeAdded && (
        <SearchBooks
          searchTerms={searchTerms}
          getSuggestions={getSuggestions}
        />
      )}
      {searching && (
        <CenterContent>
          <CircularProgress />
        </CenterContent>
      )}
      {error && "Es gab ein Problem mit deiner Anfrage"}
      {hasSearched && !searching && !bookToBeAdded && results ? (
        <SearchedBooks data={results} setBookToBeAdded={setBookToBeAdded} />
      ) : (
        !results && hasSearched && "No Results"
      )}
      {bookToBeAdded && (
        <NewBook
          bookToBeAdded={bookToBeAdded}
          setBookToBeAdded={setBookToBeAdded}
        />
      )}
    </React.Fragment>
  );
}

export default AddBook;
